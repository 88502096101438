'use client';

import styles from './NavFooter.module.scss';
import { NavDivider } from './NavDivider';
import { HelpPopoverButton } from './HelpPopover';

export function NavFooter({ collapseInMdViewports = false }: { collapseInMdViewports?: boolean }) {
  return (
    <div className={styles.footer}>
      <NavDivider />
      <HelpPopoverButton collapseInMdViewports={collapseInMdViewports} />
    </div>
  );
}
