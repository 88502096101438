import cx from 'classnames';

import styles from './NavDivider.module.scss';

export function NavDivider({
  className,
  variant = 'solid',
  ...rest
}: React.ComponentPropsWithoutRef<'div'> & {
  variant?: 'solid';
}) {
  return <div className={cx(styles.divider, styles[variant], className)} {...rest}></div>;
}
