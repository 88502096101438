import { NextRequest, NextResponse } from 'next/server';

import { LinkTo } from '@/lib/links';

import { getUserInfoFromCookies } from './read-cookies';
import { Roles, UserInfo } from './user-info';

export function permissionsMiddleware(request: NextRequest): NextResponse<unknown> | null {
  try {
    const { pathname, search } = request.nextUrl;
    const isAuthRoute = pathname.startsWith('/auth');

    // Get user info from cookies
    const [err, user] = getUserInfoFromCookies(request.cookies);
    const isAuthenticated = !err && !!user;

    if (isAuthRoute) {
      if (isAuthenticated) {
        const { searchParams } = request.nextUrl;
        const redirectTo = searchParams.get('from') || LinkTo.root();
        return NextResponse.redirect(new URL(redirectTo, request.url));
      }
      // Allow access to auth routes if not authenticated
      return null;
    }

    if (!isAuthenticated) {
      const from = pathname + search;
      return NextResponse.redirect(new URL(LinkTo.signIn({ from }), request.url));
    }

    // User is authenticated; check permissions
    const hasAccess = checkEntitlementsAccess({ user, request });
    if (hasAccess) {
      return hasAccess;
    }

    return null; // User is authenticated and has access
  } catch (err) {
    console.error(err, 'permissionsMiddleware crashed');
    return NextResponse.redirect(new URL('/auth/sign-out', request.url));
  }
}

function checkEntitlementsAccess({ user, request }: { user: UserInfo; request: NextRequest }) {
  const response404 = NextResponse.redirect(new URL('/404', request.url));
  const pathname = request.nextUrl.pathname;

  if (getSectionRegExp('compliance').test(pathname) && !user.permissions['network-compliance'].canAccess) {
    return response404;
  }

  if (getSectionRegExp('vaults').test(pathname) && !user.permissions.vaults.canAccess) {
    return response404;
  }

  if (getSectionRegExp('reports').test(pathname) && !user.permissions.reports.canAccess) {
    return response404;
  }

  if (getSectionRegExp('dapps').test(pathname) && !user.permissions.dapps.canAccess) {
    return response404;
  }

  if (getSectionRegExp('admin/settings/billing').test(pathname) && !user.permissions.billing.canAccess) {
    return response404;
  }

  if (getSectionRegExp('admin/settings/governance').test(pathname) && !user.permissions.governance.canAccess) {
    return response404;
  }

  if (
    getSectionRegExp('admin/settings').test(pathname) &&
    !(user.role === Roles.Admin || user.role === Roles.AdminCompliance)
  ) {
    return response404;
  }

  return null; // User has the required permissions
}

function getSectionRegExp(
  section:
    | 'getting-started'
    | 'compliance'
    | 'vaults'
    | 'reports'
    | 'dapps'
    | 'admin/settings'
    | 'admin/settings/governance'
    | 'admin/settings/billing',
) {
  return new RegExp(`^(\\/[a-z]{2})?\\/${section}`);
}
