'use client';

import cx from 'classnames';
import React, { forwardRef } from 'react';

import { IconName, Icons } from '@/lib/io-kit/Icons';

import styles from './NavButton.module.scss';

export const NavButton = forwardRef<
  HTMLButtonElement,
  {
    iconBefore?: IconName;
    label: string;
    dataTestId?: string;
    iconAfter?: IconName;
    collapseInMdViewports?: boolean;
    onClick?: () => void;
  }
>(({ iconBefore, label, dataTestId, iconAfter, collapseInMdViewports, onClick }, ref) => {
  const buttonClasses = cx(styles.navButton, {
    [styles.collapseInMdViewports]: collapseInMdViewports ?? false,
  });

  const IconAfterWrapperClasses = cx(styles.iconAfterWrapper, {
    [styles.collapseIconAfterWrapper]: collapseInMdViewports ?? false,
  });

  const buttonContentClasses = cx(styles.navButtonContent, {
    [styles.collapseButtonContentInMdViewports]: collapseInMdViewports ?? false,
  });

  return (
    <button ref={ref} className={buttonClasses} onClick={onClick} data-testid={dataTestId}>
      <div className={buttonContentClasses}>
        {iconBefore && React.createElement(Icons[iconBefore], { className: styles.navButtonIcon })}
        <div className={styles.navButtonLabelWrapper}>
          <span className={styles.navButtonLabel}>{label}</span>
        </div>
      </div>
      {iconAfter && (
        <div className={IconAfterWrapperClasses}>
          {React.createElement(Icons[iconAfter], { className: styles.iconAfter })}
        </div>
      )}
    </button>
  );
});

NavButton.displayName = 'NavButton';
