import * as Popover from '@radix-ui/react-popover';

import { Env } from '@/lib/env';

import styles from './HelpPopover.module.scss';
import { PageLinkExternal } from './PageLink';
import { NavDivider } from './NavDivider';
import { NavButton } from './NavButton';

export function HelpPopoverButton({ collapseInMdViewports }: { collapseInMdViewports: boolean }) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <NavButton
          iconBefore="Support"
          label="Help"
          dataTestId="nav.help"
          iconAfter="More"
          collapseInMdViewports={collapseInMdViewports}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className={styles.popoverContent} side="right" align="end" sideOffset={25}>
          <PageLinkExternal href={Env.links.helpCenter} iconName="Bot" label="Ask Chatbot" variant="unstyled" />
          <NavDivider style={{ paddingTop: 0, margin: 0 }} />
          <PageLinkExternal
            href={Env.links.submitHelpRequest}
            iconName="Ticket"
            label="Raise a Ticket"
            variant="unstyled"
          />
          <PageLinkExternal href={Env.links.apiReference} iconName="Page" label="API Reference" variant="unstyled" />
          <PageLinkExternal href={Env.links.platformFAQ} iconName="Home" label="Support Portal" variant="unstyled" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
