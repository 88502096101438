import { RestrictedMethods } from '@safe-global/safe-apps-sdk';
import { PermissionCapability } from '@iofinnet/io-core-dapp-utils-bridge-communicator';

type PermissionsDisplayType = {
  displayName: string;
  description: string;
};

export type DappPermissionKey = RestrictedMethods | PermissionCapability;

const getDappPermissionsTexts = () => ({
  [RestrictedMethods.requestAddressBook]: {
    displayName: 'Address Book',
    description: 'Access to your address book',
  },
  [PermissionCapability.RUN_ACTION]: {
    displayName: 'Run Action',
    description: 'Permission to execute actions',
  },
  [PermissionCapability.RUN_QUERY]: {
    displayName: 'Run Query',
    description: 'Permission to execute queries',
  },
  [PermissionCapability.LOAD_METADATA]: {
    displayName: 'Load Metadata',
    description: 'Permission to load metadata',
  },
  [PermissionCapability.ACCESS_TOKENS]: {
    displayName: 'Access Tokens',
    description: 'Permission to access tokens',
  },
  [PermissionCapability.SWITCH_VAULT]: {
    displayName: 'Switch Vault',
    description: 'Permission to switch vault',
  },
  [PermissionCapability.SWITCH_NETWORK]: {
    displayName: 'Switch Network',
    description: 'Permission to switch network',
  },
  [PermissionCapability.SUBSCRIBE]: {
    displayName: 'Subscribe',
    description: 'Permission to subscribe to events',
  },
  [PermissionCapability.NAVIGATE_TO]: {
    displayName: 'Navigate To',
    description: 'Permission to navigate to a specific dashboard page',
  },
  [PermissionCapability.ROUTER_CHANGE]: {
    displayName: 'Router Change',
    description: 'Permission to synchronize the dashboard url',
  },
});

export const getDappPermissionDisplayValues = (method: DappPermissionKey): PermissionsDisplayType | undefined => {
  const DAPP_PERMISSIONS_TEXTS = getDappPermissionsTexts();
  return DAPP_PERMISSIONS_TEXTS[method];
};
