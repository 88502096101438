import { z } from 'zod';

import { Network } from '@/lib/models/networks';

export type ConnectFormType = z.infer<typeof ConnectFormSchema>;
export const ConnectFormSchema = z.object({
  vaultId: z.string(),
  network: z.nativeEnum(Network.Type),
  source: z.object({
    id: z.string(),
    addressHash: z.string(),
    assetId: z.string(),
    balanceAsCoin: z.string(),
    balanceUsd: z.string(),
  }),
});

export type ConnectApiType = z.infer<typeof ConnectApiSchema>;
export const ConnectApiSchema = z.object({}).merge(ConnectFormSchema).partial();
