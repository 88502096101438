import { graphql } from '@/lib/gql';

export const WalletConnectFormVaultFragment = graphql(`
  fragment WalletConnectFormVault on VaultDetails {
    id
    name
    threshold
  }
`);

export const WalletConnectFormAddressFragment = graphql(`
  fragment WalletConnectFormAddress on Address {
    id
    balanceAsCoin: balance
    balanceUsd: balance(asFiat: USD)
    addressHash: publicKey
    asset {
      id
      ...AssetModel
      ...AssetIcon
    }
  }
`);

export const WalletConnectFormSignerFragment = graphql(`
  fragment WalletConnectFormSigner on Signer {
    device {
      user {
        id
      }
    }
  }
`);

export const WalletConnectFormFragment = graphql(`
  fragment WalletConnectForm on VaultV2 {
    id
    details {
      name
      threshold
      balanceUsd: balance(asFiat: USD)
      visibleAssets {
        id
        ...WalletConnectFormAddress
      }
      ...WalletConnectFormVault
      signers {
        ...WalletConnectFormSigner
      }
    }
  }
`);

export const WalletConnectVaultListItemFragment = graphql(`
  fragment WalletConnectVaultListItem on VaultV2 {
    __typename
    id
    details {
      ...WalletConnectFormVault
    }
  }
`);
